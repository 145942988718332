import React from "react"
import ReactDOM from "react-dom"
import "./styles/reset.css"
import "./styles/fonts.css"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0FJV7x7R66JG-a4dM_gqvwzfhgH31WoU",
  authDomain: "goldenurea-f8da7.firebaseapp.com",
  projectId: "goldenurea-f8da7",
  storageBucket: "goldenurea-f8da7.appspot.com",
  messagingSenderId: "1078269145265",
  appId: "1:1078269145265:web:92d4dffe7065aaea31b290",
  measurementId: "G-1M7D7WTM26",
}

// Initialize Firebase
initializeApp(firebaseConfig)

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
