import React from "react"
import "./App.css"

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="content">
          <div className="title-holder">
            <h1>Golden Urea Company</h1>
            <p>
              Website coming soon. Please check back to know more. Shoot us an
              email if you're curious.
            </p>
          </div>
          <a href="mailto:sales@goldenurea.org">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span>
            <a
              className="underlined"
              href="https://goldenurea.org/"
              title="Golden Urea Company"
              target="_blank"
              rel="noopener noreferrer"
            >
              © 2022 - Golden Urea Company
            </a>{" "}
          </span>
        </div>
      </div>
    )
  }
}

export default App
